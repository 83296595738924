import Link from "next/link";

import { styled } from "@theme";

export function HeaderNavItem({
  children,
  variant,
  isActive,
  href,
  handleScroll = () => {},
}) {
  return (
    <Item variant={variant} className={isActive ? "active" : ""} onClick={handleScroll}>
      <Link href={href}>
        {children}
      </Link>
    </Item>
  );
}

const Item = styled("li", {
  fontWeight: "$400",
  textAlign: "center",

  letterSpacing: "calc(200rem / 1000)",
  textTransform: "var(--text-transform-uppercase)",

  fontSize: "10px",
  "@1": {
    /* 0.75rem(12px) @ 20rem(320px) increasing to 1.5rem(24px) @ 160rem(2560px) */
    fontSize: `clamp(
      0.75rem,
      calc(0.75rem + ((1vw - 0.2rem) * 0.5357)),
      1.5rem
    )`,
  },

  /* Safari resize fix */
  minHeight: "0vw",

  /* 0.875rem(14px) @ 20rem(320px) increasing to 1.8125rem(29px) @ 160rem(2560px) */
  lineHeight: `clamp(
    0.875rem,
    calc(0.875rem + ((1vw - 0.2rem) * 0.6696)),
    1.8125rem
  )`,

  paddingTop: `clamp(
    0.1875rem,
    calc(0.1875rem + ((1vw - 0.24375rem) * 0.1382)),
    0.375rem
  )`,

  // Limit mobile width to make it take 2 lines
  maxWidth: "10ch",

  "@4": {
    maxWidth: "unset",
    
    "+ li": {
      marginLeft: "$2x_3",
    },
  },

  borderTop: "1px solid transparent",
  borderBottom: "1px solid transparent",

  variants: {
    variant: {
      light: {
        color: "var(--color-646464)",

        "&.active": {
          borderBottom: "1px solid var(--color-000000)",
          color: "var(--color-000000)",
        },

        "@media (hover: hover)": {
          "&:hover": {
            color: "var(--color-000000)",
            borderBottom: "1px solid var(--color-000000)",
          },
        },
      },
      dark: {
        color: "var(--color-dcdcdc)",

        "&.active": {
          borderBottom: "1px solid var(--color-ffffff)",
          color: "var(--color-ffffff)",
        },

        "@media (hover: hover)": {
          "&:hover": {
            color: "var(--color-ffffff)",
            borderBottom: "1px solid var(--color-ffffff)",
          },
        },
      },
      spirograph: {
        color: "white",

        "&.active": {
          borderBottom: "1px solid white",
          color: "white",
        },
        "@media (hover: hover)": {
          "&:hover": {
            borderBottom: "1px solid white",
            color: "white",
          },
        },
      },
      change_and_co_partner: {
        color: "white",

        "&.active": {
          borderBottom: "1px solid white",
          color: "white",
        },

        "@media (hover: hover)": {
          "&:hover": {
            color: "white",
            borderBottom: "1px solid white",
          },
        },
      },
      exuberance_partner: {
        color: "white",

        "&.active": {
          borderBottom: "1px solid white",
          color: "white",
        },

        "@media (hover: hover)": {
          "&:hover": {
            color: "white",
            borderBottom: "1px solid white",
          },
        },
      },
      digital_ninjas_partner: {
        color: "white",

        "&.active": {
          borderBottom: "1px solid white",
          color: "white",
        },

        "@media (hover: hover)": {
          "&:hover": {
            color: "white",
            borderBottom: "1px solid white",
          },
        },
      },
    },
  },

  // Mobile color is always black
  "@media (max-width: 769px)": {
    color: "var(--color-646464) !important",
    "&.active": {
      color: "black !important",
      borderBottom: "1px solid black !important",
    },
  },

  a: {
    color: "inherit",
  },
});
